import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import localeId from '@angular/common/locales/id';
import {ErrorHandler, inject, LOCALE_ID, NgModule, provideAppInitializer} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {ClarityModule} from '@clr/angular';
import {createErrorHandler, TraceService} from '@sentry/angular';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './core/auth/auth.service';
import {InterceptorService} from './core/interceptor/interceptor.service';

registerLocaleData(localeId, 'id');

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'id-ID'},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    provideNgxMask(),
    AuthService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
